import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  createAcquerirTerrain,
  fetchAcquerirTerrain,
  fetchColony,
} from '../features/authSlice';
import { useDispatch } from 'react-redux';
import { updateSurface } from '../features/authSlice';
import {
  GiAxeSword,
  GiAttachedShield,
  GiBrokenHeartZone,
} from 'react-icons/gi';
import logo from '../assets/provisoire_image.jpg';
import { useTranslation } from 'react-i18next';
import TDCInfo from '../components/InformationModal.js/TDCInfo';
import antsMessage from '../assets/angry_messagerie.png';
import { IoEarth } from 'react-icons/io5';
import BlackAntsAnimation from '../components/assets/AnimationModal/blackAntsAnimation';
import boump from '../assets/sounds/Button_standart.mp3';
import { useNavigate } from 'react-router-dom';

const URL = process.env.REACT_APP_SERVER_URL;

const AcuerirTerrain = ({ socket }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [armyPositions, setArmyPositions] = useState([]);
  const [surface_request, setSurface_request] = useState(0);
  const [remainingTime, setRemainingTime] = useState([]);
  const [unitCount, setUnitCounts] = useState(new Array(32).fill(0));
  const [units, setUnits] = useState([]);
  const [goToMessage, setGoToMessage] = useState('');
  const [showResourceInfo, setShowResourceInfo] = useState(false);
  const [animation, setAnimation] = useState(0);

  const colony = useSelector((state) => state.auth.colony);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const armyDeployed = useSelector((state) => state.auth.acquerir_terrain);

  const handleResourceInfoClose = () => {
    setShowResourceInfo(false);
    clickSound();
  };
  const handleResourceInfoShow = () => {
    setShowResourceInfo(true);
    clickSound();
  };

  const formatTime = (milliseconds, index) => {
    let date = new Date();

    if (milliseconds <= 0) {
      milliseconds = 60000 * (index + 1) - date.getSeconds() * 1000;
    } else {
      milliseconds += 60000;
    }

    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${days}J ${hours}H ${minutes}m ${seconds}s`;
  };

  const calculateTimeRemaining = async () => {
    if (armyDeployed && Array.isArray(armyDeployed)) {
      if (armyDeployed && armyDeployed[0] !== 'null') {
        setRemainingTime([]);
        for (let army of armyDeployed) {
          let completed_at = new Date(army.completed_at);
          let timeNow = new Date();
          if (completed_at > timeNow) {
            let difference = completed_at.getTime() - timeNow.getTime();
            setRemainingTime((prev) => [...prev, difference]);
          } else {
            setRemainingTime((prev) => [...prev, 0]);
            await fetchArmyUnits();
            dispatch(fetchAcquerirTerrain())
            setGoToMessage(
              "Retrouvez les résultats de vos attaques dans l'onglet Messagerie"
            );
          }
        }
      }
    } else {
      let tmp = [armyDeployed]; /////////////////problème lors de la création d'une attaque un objet (à la place d'un array) est envoyé par un GET
      if (tmp && tmp[0] !== 'null') {
        setRemainingTime([]);
        for (let army of tmp) {
          let completed_at = new Date(army.completed_at);
          let timeNow = new Date();

          if (completed_at > timeNow) {
            let difference = completed_at.getTime() - timeNow.getTime();
            setRemainingTime((prev) => [...prev, difference]);
          } else {
            setRemainingTime((prev) => [...prev, 0]);
            await fetchArmyUnits();
            setGoToMessage(
              "Retrouvez les résultats de vos attaques dans l'onglet Messagerie"
            );
          }
        }
      }
    }
  };

  const handleInputChange = (count, unitId) => {
    const newUnitCounts = [...unitCount];
    newUnitCounts[unitId] = count;
    setUnitCounts(newUnitCounts);
  };

  const clickSound = () => {
    let audio = document.getElementById('audio_btn');
    audio.volume = 0.1;
    audio.play();
  };

  const handleCreateArmyDeployed = async () => {
    if (surface_request > 0) {
      let k = 0;
      for (let unit of unitCount) {
        if (unit > 0) {
          k++;
        }
      }
      if (k === 0) {
        return setGoToMessage('no_unit');
      }
      dispatch(createAcquerirTerrain({ unitCount, surface_request }));
      setGoToMessage('army_repartition');
      setAnimation(2);
      clickSound();
      setTimeout(() => {
        setAnimation(0);
      }, 2000);
    } else {
      setGoToMessage('no_surface');
    }
  };

  const TotalUnits = (index) => {
    let totalUnits = 0;
    let unitTower =
      armyPositions.length !== 0
        ? armyPositions[0].ArmyUnits.find(
            (unit) =>
              unit.unit_id === index + 1 &&
              unit.status === 0 &&
              unit.unit_id !== 1
          )
        : 0;
    if (unitTower) {
      totalUnits += unitTower.count;
    }
    let unitTerritory =
      armyPositions.length !== 0
        ? armyPositions[1].ArmyUnits.find(
            (unit) =>
              unit.unit_id === index + 1 &&
              unit.status === 0 &&
              unit.unit_id !== 1
          )
        : 0;
    if (unitTerritory) {
      totalUnits += unitTerritory.count;
    }
    let unitHome =
      armyPositions.length !== 0
        ? armyPositions[2].ArmyUnits.find(
            (unit) =>
              unit.unit_id === index + 1 &&
              unit.status === 0 &&
              unit.unit_id !== 1
          )
        : 0;
    if (unitHome) {
      totalUnits += unitHome.count;
    }
    let unitRoyalRoom =
      armyPositions.length !== 0
        ? armyPositions[3].ArmyUnits.find(
            (unit) =>
              unit.unit_id === index + 1 &&
              unit.status === 0 &&
              unit.unit_id !== 1
          )
        : 0;
    if (unitRoyalRoom) {
      totalUnits += unitRoyalRoom.count;
    }
    return totalUnits;
  };

  const fetchArmyUnits = async () => {
    try {
      if (colony) {
        const response = await axios.get(`${URL}/api/army/units/${colony.id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setArmyPositions(response.data);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching army units:', error);
    }
  };

  const fetchUnits = async () => {
    try {
      const response = await axios.get(`${URL}/api/units`);
      setUnits(response.data);
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };

  useEffect(() => {

    const handleFetchColony = (data) => {
      if (colony && data.id === colony.id) {
        dispatch(fetchColony(navigate));
        console.log('fetch colonyModel');
      }
    };
    const handleFetchArmyUnits = async (data) => {
      if (colony && data.id === colony.id) {
        await fetchArmyUnits();
        console.log('fetch armyUnitModel');
      }
    };
    const handleFetchAcquerirTerrain = async (data, callback) => {
      console.log('fetch acquerirTerrainModel');
      if (colony && data.id === colony.id) {
        dispatch(fetchAcquerirTerrain());
        console.log('fetch acquerirTerrainModel');
      }
    };    

      socket.on('colonyModel', handleFetchColony);
      socket.on('acquerirTerrainModel', handleFetchAcquerirTerrain);
      socket.on('armyUnitModel', handleFetchArmyUnits);

 
    return () => {
      socket.off('colonyModel', handleFetchColony);
      socket.off('acquerirTerrainModel', handleFetchAcquerirTerrain);
      socket.off('armyUnitModel', handleFetchArmyUnits);
    };

  }, [socket, colony, dispatch, navigate]);

  useEffect(() => {
    if (!colony) {
      dispatch(fetchColony(navigate));
    }
    if (units && units.length === 0 && colony) {
      const handleUnits = async () => {
        await fetchUnits();
      };
      handleUnits();
    }
    if (armyPositions && armyPositions.length === 0 && colony) {
      const handleArmyUnits = async () => {
        await fetchArmyUnits();
      };
      handleArmyUnits();
    }
    if (
      !Array.isArray(armyDeployed) ||
      (armyDeployed && armyDeployed.length === 0 && colony)
    ) {
      const handleAquerirTerrain = async () => {
        dispatch(fetchAcquerirTerrain());
      };
      handleAquerirTerrain();
    }

    const intervalId = setInterval(() => {
      setGoToMessage('');
    }, 5000);

    if (
      remainingTime &&
      remainingTime.length === 0 &&
      Array.isArray(armyDeployed) && 
      armyDeployed[0] !== 'null'
    ) {
      calculateTimeRemaining();
    }

    const interval = setInterval(() => {
      calculateTimeRemaining();
    }, 900);

    return () => {
      clearInterval(intervalId);
      clearInterval(interval);
    };
  }, [
    colony,
    units,
    armyPositions,
    armyDeployed,
    remainingTime,
    dispatch,
    navigate,
  ]);

  return (
    <div className='aquerir-page'>
      <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>
      </audio>
      <div className='ressource-handler'>
        <div className='ressource-title'>
          <h2>
            <IoEarth className=' icon_ressource_modal icon_color_earth' />{' '}
            {t('surface_conquerir')}
          </h2>
          {goToMessage ? (
            goToMessage !== '' ? (
              <div>
                <a href='/message_tdc'>
                  <img src={antsMessage} className='logo' alt='Logo' />
                </a>
              </div>
            ) : null
          ) : null}
          <button onClick={handleResourceInfoShow} className='button_info'>
            i
          </button>
        </div>
        <TDCInfo
          show={showResourceInfo}
          handleClose={handleResourceInfoClose}
        />
        {armyDeployed && remainingTime
          ? remainingTime.map((time, index) => (
              <div className='component_tdc' key={index}>
                <p>
                  {t('surface_request')} {index + 1}:{' '}
                  {armyDeployed[index] &&
                  armyDeployed[index].surface_request !== null
                    ? armyDeployed[index].surface_request
                    : ''}{' '}
                  <IoEarth className=' icon_ressource_modal icon_color_earth' />
                  , {t('temps_restant')} {formatTime(time, index)}
                </p>
              </div>
            ))
          : null}
        {units.length !== 0 && armyPositions && armyPositions.length > 0 ? (
          <div className='acquerrir-army'>
            <div className='unit-count'>
              <table className='acquerrir-table'>
                <tbody>
                  {units.map((unit, index) => {
                    if (
                      armyPositions &&
                      armyPositions.length > 0 &&
                      unit.id > 1 &&
                      TotalUnits(index) > 0
                    ) {
                      return (
                        <tr key={index} className='tr_tdc'>
                          <td>{TotalUnits(index)}</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
            <div>
              <table className='acquerrir-table'>
                <tbody>
                  {units.map((unit, index) =>
                    unit.id != 1 && TotalUnits(index) > 0 ? (
                      <tr className='tr_tdc' key={index}>
                        <td>
                          <img
                            src={`${URL}${unit.imageUrl}` || logo}
                            alt='unit'
                            className='image_around_tdc'
                          />
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
            <div>
              <table className='acquerrir-table'>
                <tbody>
                  {units.map((unit, index) =>
                    unit.id != 1 && TotalUnits(index) > 0 ? (
                      <tr className='tr_tdc' key={index}>
                        <td>
                          <GiAxeSword className='fs25 ml10 icon_color_dore fs_responsive_logo' />
                          {''}
                          {unit.attaque}
                        </td>
                        <td>
                          <GiAttachedShield className='fs22 ml10 icon_color_dore fs_responsive_logo' />
                          {unit.defense}
                        </td>
                        <td>
                          <GiBrokenHeartZone className='fs25 icon_color_dore fs_responsive_logo' />
                          {''}
                          {unit.vie}
                        </td>
                        <td> </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
            <div>
              <table className='acquerrir-table'>
                <tbody>
                  {units.map((unit, index) => {
                    return unit.id != 1 && TotalUnits(index) > 0 ? (
                      <tr className='form-army tr_tdc' key={index}>
                        <td>
                          <input
                            type='number'
                            placeholder='0'
                            value={unitCount[index]}
                            onChange={(e) => {
                              let unitTower = armyPositions[0].ArmyUnits.find(
                                (unit) =>
                                  unit.unit_id === index + 1 &&
                                  unit.status === 0 &&
                                  unit.unit_id !== 1
                              )
                                ? armyPositions[0].ArmyUnits.find(
                                    (unit) =>
                                      unit.unit_id === index + 1 &&
                                      unit.status === 0 &&
                                      unit.unit_id !== 1
                                  )
                                : 0;
                              let unitTerritory =
                                armyPositions[1].ArmyUnits.find(
                                  (unit) =>
                                    unit.unit_id === index + 1 &&
                                    unit.status === 0 &&
                                    unit.unit_id !== 1
                                )
                                  ? armyPositions[1].ArmyUnits.find(
                                      (unit) =>
                                        unit.unit_id === index + 1 &&
                                        unit.status === 0 &&
                                        unit.unit_id !== 1
                                    )
                                  : 0;
                              let unitHome = armyPositions[2].ArmyUnits.find(
                                (unit) =>
                                  unit.unit_id === index + 1 &&
                                  unit.status === 0 &&
                                  unit.unit_id !== 1
                              )
                                ? armyPositions[2].ArmyUnits.find(
                                    (unit) =>
                                      unit.unit_id === index + 1 &&
                                      unit.status === 0 &&
                                      unit.unit_id !== 1
                                  )
                                : 0;
                              let unitRoyalRoom =
                                armyPositions[3].ArmyUnits.find(
                                  (unit) =>
                                    unit.unit_id === index + 1 &&
                                    unit.status === 0 &&
                                    unit.unit_id !== 1
                                )
                                  ? armyPositions[3].ArmyUnits.find(
                                      (unit) =>
                                        unit.unit_id === index + 1 &&
                                        unit.status === 0 &&
                                        unit.unit_id !== 1
                                    )
                                  : 0;
                              Number(e.target.value) >
                              (unitTower.count || 0) +
                                (unitTerritory.count || 0) +
                                (unitHome.count || 0) +
                                (unitRoyalRoom.count || 0)
                                ? handleInputChange(
                                    (unitTower.count || 0) +
                                      (unitTerritory.count || 0) +
                                      (unitHome.count || 0) +
                                      (unitRoyalRoom.count || 0),
                                    index
                                  )
                                : Number(e.target.value) < 0
                                  ? handleInputChange(0, index)
                                  : handleInputChange(
                                      Number(e.target.value),
                                      index
                                    );
                            }}
                          ></input>
                          <button
                            className='button_1 ressource-button-unit'
                            onClick={(e) => {
                              handleInputChange(TotalUnits(index), index);
                              clickSound();
                            }}
                          >
                            {t('max')}
                          </button>
                        </td>
                      </tr>
                    ) : null;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div>
            <p>{t('noUnit')}</p>
          </div>
        )}
        <div className='form-army-list'>
          <IoEarth className=' icon_ressource_modal icon_color_earth' />
          <input
            type='number'
            placeholder='0'
            value={surface_request}
            onChange={(e) => {
              setSurface_request(e.target.value);
            }}
          />
        </div>
        <div className='animation-tdc1'>
          {BlackAntsAnimation(animation || 0)}
        </div>
        <div className='animation-tdc2'>
          {BlackAntsAnimation(animation || 0)}
        </div>
        <div className='animation-tdc3'>
          {BlackAntsAnimation(animation || 0)}
        </div>
        {goToMessage ? (
          goToMessage !== '' ? (
            <div>
              <p className='ressource-info-repartition'>{t(goToMessage)}</p>
              <a href='/message_tdc'></a>
            </div>
          ) : null
        ) : null}
        <button
          onClick={handleCreateArmyDeployed}
          className='button_1 ressource-button'
        >
          {t('click')}
        </button>
      </div>
    </div>
  );
};

export default AcuerirTerrain;
