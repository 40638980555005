import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchColony, fetchMessagesBattles, fetchMessagesBattlesDeffence, fetchMessagesBattlesTDC, logout } from '../features/authSlice';
import ResourceModal from './ResourceModal';
import logo from '../assets/favicon.ico.png';
import sprite from '../assets/angry-guide.png';
import spriteMessage from '../assets/angry_messagerie.png';
import LanguageSwitcher from './LanguageSwitcher';
import { BsChevronDown } from 'react-icons/bs';
import ChallengeTuto from './ChallengeTuto';
import ChallengeDaily from './ChallengeDaily';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import IconFood from '../assets/IconFood.png';
import IconWater from '../assets/IconWater.png';
import IconHoney from '../assets/IconHoney.png';
import IconWood from '../assets/IconWood.png';

import Labo from '../assets/laboratoire1.png';


import Forest_Tropical from '../assets/sounds/Forest_Tropical.mp3';
import IntroModal from './assets/IntroModal';
import { useLocation, useNavigate } from 'react-router-dom';
import boump from '../assets/sounds/Button_standart.mp3'
import { FiSettings } from 'react-icons/fi';
import { TbMusicOff } from "react-icons/tb";
import { TbMusic } from "react-icons/tb";

const URL = process.env.REACT_APP_SERVER_URL;

const NavBar = ({socket}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate()

  const colony = useSelector((state) => state.auth.colony);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const userId = useSelector((state) => state.auth.user?.id);
  const numberAttacksRead = useSelector((state)=> state.auth.messagesBattlesAttacks?.some(message => message.read === 0))
  const numberDeffencesRead = useSelector((state)=> state.auth.messagesBattlesDeffences?.some(message => message.read === 0))
  const numberTDCRead = useSelector((state)=> state.auth.messagesBattlesTDC?.some(message => message.read === 0))

  const [showResourceModal, setShowResourceModal] = useState(false);
  const [showTutoModal, setShowTutoModal] = useState(false);
  const [showDailyModal, setShowDailyModal] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [rewardTuto, setRewardTuto] = useState(false);
  const [rewardDaily, setRewardDaily] = useState(false);
  const [guildId, setGuildId] = useState(0)
  const [soundparam, setSoundParam] = useState(false)

  const audio = document.getElementById("audio")

  const savedTime = localStorage.getItem("currentTimeAudio")

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleResourceModalClose = () => setShowResourceModal(false);
  const handleResourceModalShow = () => setShowResourceModal(true);
  const handleTutoModalClose = () => {
    setShowTutoModal(false)
    clickSound()
  };       ////challenge tuto 
  const handleTutoModalShow = () => {
    setShowTutoModal(true)
    clickSound()
  };
  const handleDailyModalClose = () => {
    setShowDailyModal(false)
    clickSound()
  };     ///challenge quotidient
  const handleDailyModalShow = () => {
    setShowDailyModal(true)
    clickSound()
  };
  const handleIntroModalClose = () => {
    setShowIntroModal(false)
    if (location.state.register !== null) {
      location.state.register = false
    }
    return
  };     ///intro apres register
  const handleIntroModalShow = () => {
    setShowIntroModal(true)
    if (location.state.register !== null) {
      location.state.register = false
    }
    return
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const closeMenu = () => {
    setExpanded(false);
  };

  useEffect(() => {
    const handleFetchColony = (data) => {
      if(data.id === colony.id){
        dispatch(fetchColony(navigate))
        console.log("fetch colonyModel")
      }
    }

    const handleFetchMessagesBattles = async(data) => {
      if(data.id === colony.id){
        dispatch(fetchMessagesBattles())
        console.log("fetch battleModel")
      }
    }
    const handleFetchMessagesBattlesDeffence = async(data) => {
      if(data.id === colony.id){
        dispatch(fetchMessagesBattlesDeffence())
        console.log("fetch battleModel")
      }
    }
    const handleFetchMessagesBattlesTDC = async(data) => {
      console.log("fetch battleModel")
      if(data.id === colony.id){
        dispatch(fetchMessagesBattlesTDC())
        console.log("fetch battleModel")
      }
    }
    console.log("test")
    socket.on('colonyModel', handleFetchColony )
    socket.on('battleModel', handleFetchMessagesBattles)
    socket.on('battleModelDeffence', handleFetchMessagesBattlesDeffence)
    socket.on('battleModelTDC', handleFetchMessagesBattlesTDC)

    return ( ) => {
      socket.off('colonyModel', handleFetchColony)
      socket.off('battleModel', handleFetchMessagesBattles)
      socket.off('battleModelDeffence', handleFetchMessagesBattlesDeffence)
      socket.off('battleModelTDC', handleFetchMessagesBattlesTDC)

    }
  }, [socket])

  useEffect(()=>{
    if(!colony ){
      dispatch(fetchColony(navigate))
    }
    if(colony && !numberAttacksRead){
      const handleMessagesBattles = async() => {
        dispatch(fetchMessagesBattles())
      }
      handleMessagesBattles()
    
    }
    if(colony && !numberDeffencesRead){
      const handleMessagesBattles = async() => {
        dispatch(fetchMessagesBattlesDeffence())
      }
      handleMessagesBattles()
    
    }
    if(colony && !numberTDCRead){
      const handleMessagesBattles = async() => {
        dispatch(fetchMessagesBattlesTDC())
      }
      handleMessagesBattles()
    }
    if(colony && soundparam === null){
      const handleSound = async() => {
        await getMusic()
      }
      handleSound()
    }

  })

  useEffect(() => {
    if (audio && audio !== false) {
      audio.volume = 0.1;
      if (savedTime) {  
        audio.currentTime = savedTime
      }

      audio.addEventListener('timeupdate', () => {
        localStorage.setItem("currentTimeAudio", audio.currentTime)
      })

      audio.addEventListener('ended', () => {
        localStorage.removeItem('currentTimeAudio')
      })
    }
  })

  useEffect(() => {   

        if(colony){
           fetchRewardTuto();
           fetchRewardDaily();
           fetchGuildId()

        }

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
  }, [dispatch, rewardDaily]);



  const fetchRewardTuto = async () => {
    try {
      const response = await axios.get(`${URL}/api/rewardTuto/${colony.id}`);
      setRewardTuto(Object.keys(response.data).length > 0);
      return response.data
    } catch (err) { }
  };

  const fetchRewardDaily = async () => {
    try {
      const response = await axios.get(`${URL}/api/rewardDaily/${colony.id}`);
      setRewardDaily(Object.keys(response.data).length > 0);
      return response.data
    } catch (err) { }
  };

 
  const MarkAsReadedAttacks = async () => {
    const response = await axios.post(
      `${URL}/api/message_attack_read/${colony.id}`
    );
    return response.data;
  };

  const fetchGuildId = async () => {
    try {
      const response = await axios.get(`${URL}/api/guildId/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data && response.data.guild_id) {
        setGuildId(response.data.guild_id);
        return response.data.guild_id;
      } else {
        console.log('guildId est vide, assignation de null');
        setGuildId(null);  // Si la réponse est vide ou ne contient pas guild_id
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du guildId :', error);
      setGuildId(null);  // En cas d'erreur, assignation de null aussi
    }
  };


  const clickSound = async() => {
    let audio = document.getElementById('audio_btn')
    audio.volume = 0.1
    audio.play()
    }

  const handleMusic = async() => {
    let audio = document.getElementById('audio')
    try {
      const response = await axios.post( `${URL}/api/user/music`,{
        userId: userId
      })
      if( response.data == true){
        setSoundParam(response.data)
        audio.play()
      }else if (response.data == false){
        audio.pause()
      }else{
        console.log("user.sound nulllll")
      }
      return

    } catch (error) {
      console.log(error)
    }
  }

  const getMusic = async() => {
    try {
      const response = await axios.get( `${URL}/api/user/music/${userId}`)
        setSoundParam(response.data)
        console.log(response.data)
        return
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Navbar
      expand='xxl'
      className={`navbar fixed-top ${scrolled ? 'scrolled' : ''} mainNav`}
      expanded={expanded}
    >
      <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>
      </audio>
      {
      soundparam !== false ?
       <audio id='audio' autoPlay >
        <source src={Forest_Tropical} type='audio/mpeg'></source>
      </audio>
      : 
      <audio id='audio' >
        <source src={Forest_Tropical} type='audio/mpeg'></source>
      </audio>
      }
     
      <Container >
        <div
          onClick={() => {
            handleResourceModalShow();
            clickSound()
          }}
          className={`nav-link-ressourceModal ${scrolled ? 'scrolled' : ''}`}
        >
          <div className="nav-ressourceModal">
            <div className="resource-column">
              <div className="resource-item ">
                <img className="resource-icon" src={IconFood} alt="Icon food" />
                <p className="resource-value">{colony ? colony.food_storage : null}</p>
              </div>
              <div className="resource-item">
                <img className="resource-icon" src={IconWood} alt="Icon wood" />
                <p className="resource-value">{colony ? colony.wood_storage : null}</p>
              </div>
            </div>
            <div className="resource-column">
              <div className="resource-item">
                <img className="resource-icon" src={IconWater} alt="Icon water" />
                <p className="resource-value">{colony ? colony.water_storage : null}</p>
              </div>
              <div className="resource-item">
                <img className="resource-icon" src={IconHoney} alt="Icon honey" />
                <p className="resource-value">{colony ? colony.honey_storage : null}</p>
              </div>
            </div>
            <BsChevronDown className="dropdown-icon" />
          </div>
        </div>
        <Navbar.Brand href='/'>
          <img src={logo} className='logo_nav' alt='Logo' />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='basic-navbar-nav'
          onClick={() => {
            handleToggle();
            clickSound()
          }}
        />
        <div         
          //className={`nav-link-ressourceModal ${scrolled ? 'scrolled' : ''}`}
          className={'nav-link-villageModal'}
        >
         <div className="nav-villageModal">
            <div className="village-column1">              
              <div className="resource-item ">
                <img className="village-icon" src={Labo} alt="Icon food" />
                <div className='background_div'>
                  <NavDropdown.Item href='/' className="resource-value background_div" onClick={closeMenu}>
                    {t('Reine')}
                  </NavDropdown.Item>
                </div>              
              </div>   
              <div className="resource-item">
                <img className="village-icon" src={Labo} alt="Icon honey" />
                <div className='background_div'>
                  <NavDropdown.Item className="resource-value background_div" href='/ressources' onClick={closeMenu}>
                    {t('Ressources')}
                  </NavDropdown.Item>
                </div>
              </div>             
            </div>      
            <div className="resource-column">            
              <div className="resource-item">
                <img className="village-icon" src={Labo} alt="Icon wood" />
                <div className='background_div'>
                  <NavDropdown.Item className="resource-value background_div" href='/construction' onClick={closeMenu}>
                    {t('Construction')}
                  </NavDropdown.Item>
                </div>
              </div>
              <div className="resource-item">
                <img className="village-icon" src={Labo} alt="Icon water" />
                <div className='background_div'>
                  <NavDropdown.Item className="resource-value background_div" href='/laboratoire' onClick={closeMenu}>
                    {t('Laboratoire')}
                  </NavDropdown.Item>
                </div>
              </div>
              <div className="resource-item">
                <img className="village-icon" src={Labo} alt="Icon water" />
                <div className='background_div'>
                  <NavDropdown.Item className="resource-value background_div" href='/colonies' onClick={closeMenu}>
                    {t('Colonies')}
                  </NavDropdown.Item>
                </div>
              </div>
              <div className="resource-item">
                <img className="village-icon" src={Labo} alt="Icon honey" />
                <div className='background_div'>
                  <NavDropdown.Item className="resource-value background_div" href='/map' onClick={closeMenu}>
                    {t('Carte')}
                  </NavDropdown.Item>
                </div>
              </div>
              <div className="resource-item">
                <img className="village-icon" src={Labo} alt="Icon water" />
                <div className='background_div'>
                  <NavDropdown.Item className="resource-value background_div" href='/convois' onClick={closeMenu}>
                    {t('Convoie')}
                  </NavDropdown.Item>
                </div>
              </div>  
            </div>
            <div className="village-column3">              
              <div className="resource-item">
                <img className="village-icon" src={Labo} alt="Icon water" />
                <div className='background_div'>
                  <NavDropdown.Item className="resource-value background_div" href='/army' onClick={closeMenu}>
                    {t('Armée')}
                  </NavDropdown.Item>
                </div>
              </div>
              <div className="resource-item">
                <img className="village-icon" src={Labo} alt="Icon honey" />
                <div className='background_div'>
                  <NavDropdown.Item className="resource-value background_div" href='/ennemies' onClick={closeMenu}>
                    {t('Ennemies')}
                  </NavDropdown.Item>
                </div>
              </div>              
            </div>  
          </div>    
        </div>    
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            {/* <NavDropdown
              title={t('Fourmilière')}
              id='basic-nav-dropdown'
              className={` ${scrolled ? 'scrolled' : ''}`}
            >
              <div className='dropdown-columns'>
                <NavDropdown.Item href='/' onClick={closeMenu}>
                  {t('Reine')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/construction' onClick={closeMenu}>
                  {t('Construction')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/laboratoire' onClick={closeMenu}>
                  {t('Laboratoire')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/ressources' onClick={closeMenu}>
                  {t('Ressources')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/army' onClick={closeMenu}>
                  {t('Armée')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/ennemies' onClick={closeMenu}>
                  {t('Ennemies')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/colonies' onClick={closeMenu}>
                  {t('Colonies')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/map' onClick={closeMenu}>
                  {t('Carte')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/convois' onClick={closeMenu}>
                  {t('Convoie')}
                </NavDropdown.Item>
              </div>
            </NavDropdown> */}
            <NavDropdown
              title={t('Alliance')}
              id='basic-nav-dropdown'
              className={scrolled ? 'scrolled' : ''}
            >
              <div className='dropdown-columns'>
                <NavDropdown.Item href='/guilde' onClick={closeMenu}>
                  { guildId ?  "Guilde" : t('Créer / Rejoindre une Guilde')}
                </NavDropdown.Item>

                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Chat
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Forum
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Diplomatie
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Options
                </NavDropdown.Item>
              </div>
            </NavDropdown>
           
            <NavDropdown
              title={t('Communauté')}
              id='basic-nav-dropdown'
              className={scrolled ? 'scrolled' : ''}
            >
              <div className='dropdown-columns'>
              <NavDropdown.Item href='/tuto' onClick={closeMenu}>
                  {t('Introduction')}
                </NavDropdown.Item>

                {/* <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Chat
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Echange
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Proposition
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Classement Joueurs
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Classement Alliances
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Mon Profil
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Mon compte
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Parrainage
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Forum
                </NavDropdown.Item> */}
              </div>
            </NavDropdown>

            <Nav.Link
              href='/message_attack'
              className={`nav-link ${scrolled ? 'scrolled' : ''}`}
              onClick={()=> {
                closeMenu()
                MarkAsReadedAttacks()               
              }}
            >
              {t('Messagerie')}
              {
                numberAttacksRead === true ? <img src={spriteMessage} className='ant_message' alt='Logo' /> : ""
              }
              { numberDeffencesRead === true ? <img src={spriteMessage} className='ant_message' alt='Logo' /> : ""
              }
              { numberTDCRead === true ? <img src={spriteMessage} className='ant_message' alt='Logo' /> : ""
              }
            </Nav.Link>

            <NavDropdown
              title={t('Aide')}
              id='basic-nav-dropdown'
              className={scrolled ? 'scrolled' : ''}
            >
              <a className='auth-input' href='https://discord.com/channels/1256332544991953007/1256332545461588110'>Support discord</a>
              <div>
                <NavDropdown.Item href='/feedBack' onClick={closeMenu}>Feed Back</NavDropdown.Item>
              </div>
            </NavDropdown>
          </Nav>
          <Nav.Link
            className={`bouton_log ${scrolled ? 'scrolled' : ''}`}
            onClick={() => {
              handleLogout();
              closeMenu();
            }}
          >
            {t('Déconnecter')}
          </Nav.Link>
          {rewardTuto === true ? (
            <Nav.Link
              onClick={handleTutoModalShow}
              className={`nav-link-challengeTuto ${scrolled ? 'scrolled' : ''}`}
            >
              <div className='nav_tuto'>
                <p>{t('ChallengeTuto')}</p>
                <img src={sprite} className='img_challenge_nav' alt='Logo' />
              </div>
            </Nav.Link>
          ) : null}
          <ChallengeTuto
            show={showTutoModal}
            handleClose={handleTutoModalClose}
            colony={colony}
          />
          {rewardDaily === true ? (
            <Nav.Link
              onClick={handleDailyModalShow}
              className={`nav-link-challengeTuto ${scrolled ? 'scrolled' : ''}`}
            >
              <div className='nav_tuto'>
                <p>{t('Challenge')}</p>
                <img src={sprite} className='img_challenge_nav' alt='Logo' />
              </div>
            </Nav.Link>
          ) : null}
          <ChallengeDaily
            show={showDailyModal}
            handleClose={handleDailyModalClose}
            colony={colony}
          />
          {location.state && location.state.register === true ? handleIntroModalShow()
            : null}
          <IntroModal
            show={showIntroModal}
            handleClose={handleIntroModalClose}
            colony={colony}
          />
          <NavDropdown
            title={<FiSettings size={20} />}
            align="end"
            className={`settings-dropdown${scrolled ? ' scrolled-dropdown' : ''}`}
          >
            <NavDropdown.Item as="div" onClick={(event) => event.stopPropagation()}>
              <LanguageSwitcher />
              {
                soundparam && soundparam === true ?
                  <TbMusicOff onClick={(e)=> handleMusic()}/>
                :
                  <TbMusic onClick={(e)=> handleMusic()}/>
              }
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
        {/* Component au click sur les ressources */}
        <ResourceModal
          show={showResourceModal}
          handleClose={handleResourceModalClose}
          colony={colony}
        />
      </Container>
    </Navbar>
  );
};

export default NavBar;
