import axios from 'axios'
const URL = process.env.REACT_APP_SERVER_URL;

const fetchMessagesBattles = async (colony_id) => {
    const response = await axios.get(`${URL}/api/message_battle/${colony_id}`);
    return response.data;
  };

  const fetchMessagesBattlesDeffence = async (colony_id) => {
    const response = await axios.get(`${URL}/api/message_defence/${colony_id}`);
    return response.data;
  };

  const fetchMessagesBattlesTDC = async (colony_id) => {
    const response = await axios.get(`${URL}/api/battlesTDC/${colony_id}`);
    return response.data;
  };

  export default { fetchMessagesBattles, fetchMessagesBattlesDeffence, fetchMessagesBattlesTDC}


