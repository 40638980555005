import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { fetchColony } from '../features/authSlice';
import logo from '../assets/provisoire_image.jpg';
import { FaHourglassHalf, FaAppleAlt, FaHouzz } from 'react-icons/fa';
import {
  GiAxeSword,
  GiAttachedShield,
  GiBrokenHeartZone,
} from 'react-icons/gi';
import Button1 from '../components/assets/Button1';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../components/assets/ErrorModal';
import boump from '../assets/sounds/Button_standart.mp3';
import { GiCancel } from 'react-icons/gi';
import IconFood from '../assets/IconFood.png';

const URL = process.env.REACT_APP_SERVER_URL;

const Home = ({ socket }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const username = useSelector((state) => state.auth.username);
  const colony = useSelector((state) => state.auth.colony);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [units, setUnits] = useState([]);
  const [unitCounts, setUnitCounts] = useState({});
  const [productions, setProductions] = useState([]);
  const [foodCosts, setFoodCosts] = useState({});
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState({});
  const [buildingLevels, setBuildingLevels] = useState({});
  const [researchLevels, setResearchLevels] = useState({});
  const [selectedUnitId, setSelectedUnitId] = useState('');
  const [messageError, setMessageError] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [antMystere, setAntMystere] = useState(28);

  const handleErrorClose = () => setShowError(false);
  const handleErrorShow = (message) => {
    setErrorMessage(message);
    setShowError(true);
  };

  const fetchUnits = async () => {
    try {
      const response = await axios.get(`${URL}/api/units`);
      setUnits(response.data);
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };

  const fetchBuildingLevels = async () => {
    try {
      const response = await axios.get(
        `${URL}/api/colonies/${colony.id}/buildings`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const buildings = response.data.reduce((acc, building) => {
        acc[building.buildingId] = building.currentLevel;
        return acc;
      }, {});
      setBuildingLevels(buildings);
    } catch (error) {
      console.error('Error fetching building levels:', error);
    }
  };

  const fetchResearchLevels = async () => {
    try {
      const response = await axios.get(
        `${URL}/api/colonies/${colony.id}/research`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const research = response.data.reduce((acc, research) => {
        acc[research.research_id] = research.currentLevel;
        return acc;
      }, {});
      setResearchLevels(research);
    } catch (error) {
      console.error('Error fetching building levels:', error);
    }
  };

  const fetchPositions = async () => {
    try {
      const response = await axios.get(`${URL}/api/positions`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setPositions(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching positions:', error);
    }
  };

  const fetchProductions = async () => {
    try {
      const response = await axios.get(
        `${URL}/api/colonies/${colony.id}/productions`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const sortedProductions = response.data.sort(
        (a, b) => new Date(a.started_at) - new Date(b.started_at)
      );
      if (sortedProductions.length === 0) {
        setProductions(['null']);
      } else {
        setProductions(sortedProductions);
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching productions:', error);
    }
  };

  useEffect(() => {
    const handleFetchColony = (data) => {
      if (colony && data && data.id === colony.id) {
        dispatch(fetchColony(navigate));
        console.log('fetch colonyModel');
      }
    };
    const handleFetchProduction = async (data) => {
      if (colony && data && data.id === colony.id) {
        await fetchProductions();
        console.log('fetch ProductionModel');
      }
    };
    const handleFetchBuildings = async (data) => {
      if (colony && data && data.id === colony.id) {
        await fetchBuildingLevels();
        console.log('fetch colonyBuildingModel');
      }
    };
    const handleFetchResearches = async (data) => {
      if (colony && data && data.id === colony.id) {
        await fetchResearchLevels();
        console.log('fetch colonyResearchModel');
      }
    };

    socket.on('colonyModel', handleFetchColony);
    socket.on('EggProductionModel', handleFetchProduction);
    socket.on('colonyResearchModel', handleFetchResearches);
    socket.on('colonyBuildingModel', handleFetchBuildings);

    return () => {
      socket.off('colonyModel', handleFetchColony);
      socket.off('EggProductionModel', handleFetchProduction);
      socket.off('colonyResearchModel', handleFetchResearches);
      socket.off('colonyBuildingModel', handleFetchBuildings);
    };
  }, [socket]);

  useEffect(() => {
    if (!colony) {
      dispatch(fetchColony(navigate));
    }
    if (productions.length === 0 && colony) {
      const handleProduction = async () => {
        await fetchProductions();
      };
      handleProduction();
    }
    if (Object.keys(buildingLevels).length === 0 && colony) {
      const handleBuildingLevels = async () => {
        await fetchBuildingLevels();
        if (buildingLevels[1] >= 70) {
          setAntMystere(30);
        }
      };
      handleBuildingLevels();
    }
    if (Object.keys(researchLevels).length === 0 && colony) {
      const handleResearchLevels = async () => {
        await fetchResearchLevels();
      };
      handleResearchLevels();
    }
    if (units.length === 0 && colony) {
      const handleUnits = async () => {
        await fetchUnits();
      };
      handleUnits();
    }
    if (positions.length === 0 && colony) {
      const handlePositions = async () => {
        await fetchPositions();
      };
      handlePositions();
    }
  });

  const handleSpawnUnit = async (unitId) => {
    try {
      const count = unitCounts[unitId] || 1;
      const position_id = unitId === 1 ? null : selectedPosition[unitId];
      await axios.post(
        `${URL}/api/units/spawn`,
        { unitId, count, position_id },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      await fetchProductions();
      dispatch(fetchColony(navigate));
      clickSound();
    } catch (error) {
      handleErrorShow(
        'Veuillez consulter vos ressources ou veuillez vous déconnecter/reconnecter.'
      );
    }
  };

  const handleBatchSpawnUnit = async (unitId, count) => {
    try {
      const position_id =
        unitId === 1 ? null : selectedPosition[unitId] || null;
      console.log(
        `Spawning unit with ID: ${unitId}, Count: ${count}, Position ID: ${position_id}`
      );
      await axios.post(
        `${URL}/api/units/spawn`,
        { unitId, count, position_id },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchProductions();
      dispatch(fetchColony(navigate));
      clickSound();
    } catch (error) {
      console.error('Error spawning units:', error);
    }
  };

  const handleCancelProduction = async (productionId) => {
    try {
      await axios.post(
        `${URL}/api/units/cancel`,
        { productionId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      await fetchProductions();
      clickSound();
    } catch (error) {
      console.error('Error cancelling production:', error);
    }
  };

  const handleCountChange = (unitId, count) => {
    setUnitCounts((prevCounts) => ({
      ...prevCounts,
      [unitId]: count,
    }));

    const unit = units.find((unit) => unit.id === unitId);
    if (unit) {
      const totalFoodCost = unit.cout_production * count;
      setFoodCosts((prevCosts) => ({
        ...prevCosts,
        [unitId]: totalFoodCost,
      }));
    }
  };

  const handlePositionChange = (unitId, positionId) => {
    setSelectedPosition((prevPositions) => ({
      ...prevPositions,
      [unitId]: positionId,
    }));
  };

  const getProductionTime = (baseTime, count) => {
    const totalTime = baseTime * count;
    const reductionFactor = Math.pow(
      0.97,
      (buildingLevels[15] || 0) +
        (buildingLevels[16] || 0) +
        (researchLevels[1] || 0)
    );
    return totalTime * reductionFactor;
  };

  const formatTime = (milliseconds) => {
    if (milliseconds < 0) return '0J 0H 0m 0s';

    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${days}J ${hours}H ${minutes}m ${seconds}s`;
  };

  const [inputValues, setInputValues] = useState({});

  const handleInputFocus = (unitId) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [unitId]: '',
    }));
  };

  const handleInputBlur = (unitId, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [unitId]: value === '' ? 1 : value,
    }));
  };

  const clickSound = () => {
    let audio = document.getElementById('audio_btn');
    audio.volume = 0.1;
    audio.play();
  };

  return (
    <div className='mt10'>
      <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>
      </audio>
      <div className='box_home'>
        <div className='table-container'>
          <table className='table-ponte'>
            <thead>
              <tr className='tr_ponte'>
                <th className='tr_ponte'>{t('unites')}</th>
                <th className='tr_ponte'>{t('garnison')}</th>
                <th className='tr_ponte'>{t('temps_requis')}</th>
                <th className='tr_ponte'>{t('temps_total_restant')}</th>
                <th className='tr_ponte'>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {productions[0] !== 'null'
                ? productions.map((production, index) => {
                    const now = new Date();
                    const completedAt = new Date(production.completed_at);
                    const startedAt = new Date(production.started_at);
                    const elapsedTime = Math.max(0, now - startedAt);
                    const totalTime = completedAt - startedAt;
                    const remainingTime = Math.max(0, completedAt - now);
                    const timeRequired =
                      index === 0 ? remainingTime : totalTime - elapsedTime;
                    const remainingUnits = production.count;

                    return (
                      <tr key={production.id} className='tr_ponte'>
                        <td className='tr_ponte' data-label={t('unites')}>
                          {remainingUnits} {production.Unit?.name}
                        </td>
                        <td className='tr_ponte' data-label={t('garnison')}>
                          {production.Position
                            ? production.Position.name
                            : t('fourmiliere')}
                        </td>
                        <td className='tr_ponte' data-label={t('temps_requis')}>
                          {formatTime(timeRequired)}
                        </td>
                        <td
                          className='tr_ponte'
                          data-label={t('temps_total_restant')}
                        >
                          {formatTime(remainingTime)}
                        </td>
                        <td className='tr_ponte'>
                          <GiCancel
                            className='cancel_unit_ponte'
                            onClick={() =>
                              handleCancelProduction(production.id)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        <div className='container_left police_Electrolize'>
          <div className='production-controls'>
            <div className='mt5 fs18'>
              <label htmlFor='unit-select'>{t('selectionnez_une_unite')}</label>
              <select
                className='select_1 ml10 f18'
                id='unit-select'
                onChange={(e) => setSelectedUnitId(e.target.value)}
              >
                <option value=''>{t('selectionnez_une_unite')}</option>
                {units.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='production-buttons mt20'>
              <div className='button-row mt5 button'>
                <Button1
                  width='160px'
                  height='30px'
                  className='button_responsive'
                  onClick={() => handleBatchSpawnUnit(selectedUnitId, 100)}
                >
                  {t('pondre_100')}
                </Button1>
                <Button1
                  width='160px'
                  height='30px'
                  className='ml30 button_responsive'
                  onClick={() => handleBatchSpawnUnit(selectedUnitId, 1000)}
                >
                  {t('pondre_1000')}
                </Button1>
                <Button1
                  width='160px'
                  height='30px'
                  className='ml30 button_responsive'
                  onClick={() => handleBatchSpawnUnit(selectedUnitId, 10000)}
                >
                  {t('pondre_10k')}
                </Button1>
              </div>
              <div className='button-row mt5'>
                <Button1
                  width='160px'
                  height='30px'
                  className='button_responsive'
                  onClick={() => handleBatchSpawnUnit(selectedUnitId, 100000)}
                >
                  {t('pondre_100k')}
                </Button1>
                <Button1
                  width='160px'
                  height='30px'
                  className='ml30 button_responsive'
                  onClick={() => handleBatchSpawnUnit(selectedUnitId, 1000000)}
                >
                  {t('pondre_1m')}
                </Button1>
                <Button1
                  width='160px'
                  height='30px'
                  className='ml30 button_responsive'
                  onClick={() => handleBatchSpawnUnit(selectedUnitId, 10000000)}
                >
                  {t('pondre_10m')}
                </Button1>
              </div>
              <div className='button-row mt5'>
                <Button1
                  width='160px'
                  height='30px'
                  className='button_responsive'
                  onClick={() =>
                    handleBatchSpawnUnit(selectedUnitId, 100000000)
                  }
                >
                  {t('pondre_100m')}
                </Button1>
                <Button1
                  width='160px'
                  height='30px'
                  className='ml30 button_responsive'
                  onClick={() =>
                    handleBatchSpawnUnit(selectedUnitId, 1000000000)
                  }
                >
                  {t('pondre_1g')}{' '}
                </Button1>
                <Button1
                  width='160px'
                  height='30px'
                  className='ml30 button_responsive'
                  onClick={() =>
                    handleBatchSpawnUnit(selectedUnitId, 10000000000)
                  }
                >
                  {t('pondre_10g')}
                </Button1>
              </div>
            </div>
            <div className='mt20 box_niv_btrs'>
              <p>{t('niveaux_des_batiments')}</p>
              <p>{t('niveaux_des_recherches')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='unit-container'>
        <ul className='unit-list mt10'>
          {antMystere &&
            units.slice(0, antMystere).map(
              (
                unit //////on affiche pas la 31eme
              ) => (
                <li key={unit.id} className='unit-item'>
                  <div className='unit-info'>
                    <div className='header_unit_background'>
                      <div className='flex container_space'>
                        <div className='header_unit_name'>
                          <p>
                            <strong className='fs_name_unit_responsive'>
                              {unit.name}
                            </strong>
                          </p>
                        </div>
                        <div className='container_stats_unit ml10 police_Electrolize fs18 mt10'>
                          {formatTime(
                            getProductionTime(
                              unit.temp_ponte * 1000,
                              unitCounts[unit.id] || 1
                            )
                          )}
                          <FaHourglassHalf className=' mr10 icon_color_dore fs_responsive_logo_sm' />
                          <div className='flex sm_food_count'>
                            <p className='fs25'>
                              {foodCosts[unit.id] || unit.cout_production}
                            </p>

                            <img
                              className='food_icon_small'
                              src={IconFood}
                              alt='Icon food'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='header_unit_stats police_Electrolize flex'>
                        <GiAxeSword className='fs32 icon_color_dore fs_responsive_logo' />
                        <p className='fs22 ml8 lg_fs18'>{unit.attaque}</p>
                        <GiAttachedShield className='fs32 ml10 icon_color_dore fs_responsive_logo' />
                        <p className='fs22 ml8 lg_fs18'>{unit.defense}</p>
                        <GiBrokenHeartZone className='fs32 ml10 icon_color_dore fs_responsive_logo' />
                        <p className='fs22 ml8 lg_fs20'>{unit.vie}</p>
                        <FaHouzz className='fs32 ml10 icon_color_dore fs_responsive_logo' />
                        <p className='fs22 ml8 lg_fs18'>
                          {unit.required_building_level
                            ? unit.required_building_level
                            : 0}
                        </p>
                        <FaHouzz className='fs32 ml10 icon_color_dore fs_responsive_logo' />
                        <p className='fs22 ml8 lg_fs18'>
                          {unit.required_research_level
                            ? unit.required_research_level
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className='flex'>
                      <div>
                        <img
                          src={`${URL}${unit.imageUrl}` || logo}
                          alt='unit'
                          className='image_units'
                        />
                      </div>
                      <div className='police_Telescope fs20 lg_container_prod'>
                        <div className='lg_box_prod'>
                          <div className='flex mt10 lg_remove_margin'>
                            <div className='form-group'>
                              <input
                                type='number'
                                min='1'
                                id='input1'
                                value={
                                  inputValues[unit.id] !== undefined
                                    ? inputValues[unit.id]
                                    : unitCounts[unit.id] || 1
                                }
                                onFocus={() => handleInputFocus(unit.id)}
                                onBlur={(e) =>
                                  handleInputBlur(unit.id, e.target.value)
                                }
                                onChange={(e) => {
                                  const value = Math.max(
                                    1,
                                    parseInt(e.target.value) || 1
                                  );
                                  setInputValues((prevValues) => ({
                                    ...prevValues,
                                    [unit.id]: value,
                                  }));
                                  handleCountChange(unit.id, value);
                                }}
                              />
                              <label htmlFor='input1'>{t('quantite')}</label>
                            </div>
                            <Button1
                              width='100px'
                              height='42px'
                              className='mt22'
                              onClick={() => handleSpawnUnit(unit.id)}
                              disabled={
                                (unit.required_building_level &&
                                  (buildingLevels[1] || 0) <
                                    unit.required_building_level) ||
                                (unit.required_research_level &&
                                  (researchLevels[8] || 0) <
                                    unit.required_research_level)
                              }
                              style={{
                                backgroundColor:
                                  (unit.required_building_level &&
                                    (buildingLevels[1] || 0) <
                                      unit.required_building_level) ||
                                  (unit.required_research_level &&
                                    (researchLevels[8] || 0) <
                                      unit.required_research_level)
                                    ? 'grey'
                                    : '',
                                cursor:
                                  (unit.required_building_level &&
                                    (buildingLevels[1] || 0) <
                                      unit.required_building_level) ||
                                  (unit.required_research_level &&
                                    (researchLevels[8] || 0) <
                                      unit.required_research_level)
                                    ? 'not-allowed'
                                    : '',
                              }}
                            >
                              {t('pondre')}
                            </Button1>
                          </div>
                          {unit.id !== 1 && (
                            <div className='lg_remove_mt'>
                              <label
                                className='fs25'
                                htmlFor={`position-select-${unit.id}`}
                              >
                                {t('position_de_stockage')}
                              </label>
                              <select
                                className='select_1 ml5'
                                id={`position-select-${unit.id}`}
                                onChange={(e) =>
                                  handlePositionChange(unit.id, e.target.value)
                                }
                              >
                                <option value=''>
                                  {t('selectionnez_une_position')}
                                </option>
                                {positions.map((position) => (
                                  <option key={position.id} value={position.id}>
                                    {position.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          <div>
                            {(unit.required_building_level ||
                              unit.required_research_level) &&
                              ((buildingLevels[1] || 0) <
                                (unit.required_building_level || 0) ||
                                (researchLevels[8] || 0) <
                                  (unit.required_research_level || 0)) && (
                                <p className='t_required_construction'>
                                  Débloquer
                                  {buildingLevels[1] <
                                    (unit.required_building_level || 0) &&
                                    ` Caserne niveau ${unit.required_building_level || ''}`}
                                  {buildingLevels[1] <
                                    (unit.required_building_level || 0) &&
                                    researchLevels[8] <
                                      (unit.required_research_level || 0) &&
                                    ' et '}
                                  {researchLevels[8] <
                                    (unit.required_research_level || 0) &&
                                    ` Génétique niveau ${unit.required_research_level || ''}`}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            )}
        </ul>
      </div>
      <ErrorModal
        show={showError}
        handleClose={handleErrorClose}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default Home;
