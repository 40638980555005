import axios from 'axios';
const URL = process.env.REACT_APP_SERVER_URL;


const authService = {

  login: async (username, password) => {
    try {
       const response = await axios.post(`${URL}/api/auth/login`, {
      username,
      password,
    });
    return response.data;
    } catch (error) {
      console.log(error)
    }
   
  },
  register: async (username, password) => {
    const response = await axios.post(`${URL}/api/auth/register`, {
      username,
      password,
    });
    return response.data;
  },
  getColony: async (navigate, accessToken) => {
    try {
      const response = await axios.get(`${URL}/api/auth/colony`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
        if (error.response.status == 403) {
            navigate('/acceuil');
          }
    }
  },
  updateFood: async (colony_id, antsForFood, antsForWood) => {
    try {
      const response = await axios.post(`${URL}/api/ressources/update`, {
        colony_id: colony_id,
        antsForFood: antsForFood,
        antsForWood: antsForWood,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  updateWorkersCount: async (
    colony_id,
    terrain_chasse,
    antsForFood,
    antsForWood,
    maxWorkers
  ) => {
    try {
      const response = await axios.post(`${URL}/api/ressources/updateWorkers`, {
        colony_id: colony_id,
        antsForFood: antsForFood,
        antsForWood: antsForWood,
        terrain_chasse: terrain_chasse,
        maxWorkers: maxWorkers,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  updateSurface: async (accessToken, colony_id, terrain_chasse) => {
    try {
      const response = await axios.post(
        `${URL}/ressources/updateSurface`,
        {
          colony_id: colony_id,
          terrain_chasse: terrain_chasse,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
};

export default authService;
