import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Messagerie from './Messagerie';
import warrior from '../../assets/angry-speakers.png';

const URL = process.env.REACT_APP_SERVER_URL;

const MessagerieAttack = () => {
  const { t } = useTranslation();

  const [messagesBattles, setMessagesBattles] = useState([]);
  const [units, setUnits] = useState([]);

  const colony = useSelector((state) => state.auth.colony);

  const fetchMessagesBattles = async () => {
    const response = await axios.get(`${URL}/api/message_battle/${colony.id}`);
    setMessagesBattles(response.data);
    return response.data;
  };

  const fetchUnits = async () => {
    try {
      const response = await axios.get(`${URL}/api/units`);
      setUnits(response.data);
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };



  useEffect(()=> {
    if(units.length === 0 && colony){
      const handleUnits = async()=>{
        await fetchUnits()
      }
      handleUnits()
    }
  })

  useEffect(() => {
    const handler = async () => {
      if (colony) {
        await fetchMessagesBattles();
      }
    };
    handler();
  }, [colony]);

  return (
    <div className='messagerie-page'>
      {Messagerie()}
      {Array.isArray(messagesBattles) &&
        messagesBattles.map((message, index) =>
          message.attacker_colony_id == colony.id ? (
            <div key={index} className='messagerie-renderer'>
              {message.read === 0 && message.result !== 'Defender wins' ? (
                <h2 className='messagerie-h2 '>
                  {t('message_attack')}{' '}
                  <p className='message-victory'>{message.result}</p>
                </h2>
              ) : (
                <h2 className='messagerie-h2 '>
                  {t('message_attack')}{' '}
                  <p className='message-defeat'>{message.result}</p>
                </h2>
              )}{' '}
              <div>
                {message.read === 0 && message.result !== 'Defender wins' ? (
                  <div className='message_battle_area'>
                    <div className='message_battle_attacker'>
                      <img src={warrior} className='logo' alt='Logo' />
                     
                    </div>
                    <div className='message_battle_defender'>
                      <img src={warrior} className='logo' alt='Logo' />
                      
                    </div>
                  </div>
                ) : message.read === 0 && message.result === 'Defender wins' ? (
                  <div className='message_battle_area'>
                    <div className='message_battle_attacker'>
                      <img src={warrior} className='logo' alt='Logo' />
                     
                    </div>
                    <div className='message_battle_defender'>
                      <img src={warrior} className='logo' alt='Logo' />
                     
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <p>{t('pertes')} </p>
              {message.attacker_losses.map((unit, index) => (
                <p>
                  {unit.count}
                  {units.map((u) =>
                    u.id === unit.unit_id ? (
                      <img
                        src={`${URL}${u.imageUrl}` || ''}
                        alt='unit'
                        className='image_around_tdc'
                      />
                    ) : (
                      ''
                    )
                  )}{' '}
                  {index !== 0 ? ', ' : ''}{' '}
                </p>
              ))}
              <p>{t('pertes_infligées')}</p>
              {message.defender_losses.map((unit, index) => (
                <p>
                  {unit.count}
                  {units.map((u) =>
                    u.id === unit.unit_id ? (
                      <img
                        src={`${URL}${u.imageUrl}` || ''}
                        alt='unit'
                        className='image_around_tdc'
                      />
                    ) : (
                      ''
                    )
                  )}{' '}
                  {index !== 0 ? ', ' : ''}{' '}
                </p>
              ))}
            </div>
          ) : (
            <p>Need refreach</p>
          )
        )}
    </div>
  );
};

export default MessagerieAttack;
