import axios from 'axios';
const URL = process.env.REACT_APP_SERVER_URL;

const acquerirTerrainService = {
  getArmyDeployed: async (colony_id) => {
    try {
      const response = await axios.get(
        `${URL}/api/terrain_chasse/${colony_id}`
      );
      if(response.data.length === 0){
        return ["null"]
      }else{
      return response.data;
      }
    } catch (err) {
      console.log('getArmyDeployedService', err);
      return null;
    }
  },
  createArmyDeployed: async (
    colony_id,
    unitCount,
    terrain_chasse,
    surface_request
  ) => {
    try {
      surface_request = Number(surface_request);
      const response = await axios.post(
        `${URL}/api/terrain_chasse/createArmy`,
        {
          colony_id: colony_id,
          unitCount,
          terrain_chasse,
          surface_request,
        }
      );
      return response.data;
    } catch (err) {
      console.log('Service', err);
    }
  },
};

export default acquerirTerrainService;
