// src/components/ResourceModal.js
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { fetchColony } from '../features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FaAppleAlt } from 'react-icons/fa';
import { GiDrippingHoney, GiWoodStick } from 'react-icons/gi';
import { IoIosWater } from 'react-icons/io';
import { IoEarth } from 'react-icons/io5';
import workerImg from '../assets/unit_0.webp';
import { useNavigate } from 'react-router-dom';

import IconFood from '../assets/IconFood.png';
import IconWater from '../assets/IconWater.png';
import IconHoney from '../assets/IconHoney.png';
import IconWood from '../assets/IconWood.png';
import platformerImg from '../assets/img_modal_platforme.png';


const URL = process.env.REACT_APP_SERVER_URL;

const ResourceModal = ({ show, handleClose,socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [localTime, setLocalTime] = useState(new Date().toLocaleTimeString());
  const [workersCount, setWorkersCount] = useState(0);
  const colony = useSelector((state) => state.auth.colony);

  const fetchWorkers = async (colony_id) => {
    try {
      const response = await axios.get(`${URL}/api/units/workers/${colony_id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };

  const getWorkersCount = async () => {
    if (colony) {
      const count = await fetchWorkers(colony.id);
      if (count > 0) {
        setWorkersCount(count);
        return count;
      }
    }
  };

  useEffect(() => {
    const handler = async () => {
      await getWorkersCount();
    };
    dispatch(fetchColony(navigate));
    handler();
    const interval = setInterval(async () => {
      dispatch(fetchColony(navigate));
      await getWorkersCount();
    }, 1000);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setLocalTime(new Date().toLocaleTimeString());
    }, 1000);

    // Nettoyer l'intervalle à la fermeture du composant
    return () => clearInterval(timeInterval);
  }, []);


  return (
    <Modal
      show={show}
      onHide={handleClose}
      className='modale_globale'
      onClick={handleClose}
    >
      <Modal.Body>
        {colony ? (
          <div className=''>
            <div className='h2 ressourceModal_header'>
              <h2>{colony ? colony.name : null}</h2>
            </div>
            <div className='flex-container'>
              {/* Section gauche */}
              <div className='left-section'>
                <div className=''>
                  <IoEarth className='icon_ressource_modal icon_color_earth' />{' '}
                  {colony ? colony.terrain_chasse : null}
                </div>
                <div className='flex'>
                  <img src={workerImg} className='resource-icon-modal' alt='Logo' />
                  <p className='resource-value-modal'>{workersCount}</p>
                </div>
              </div>

              {/* Section droite */}
              <div className='right-section'>
                <p>Heure locale : {localTime}</p>
              </div>
            </div>
            <div>
              <img className="img_center img_mobile_modal" src={platformerImg} alt="IMAGE PLATFORMER" />
            </div>
            <div className='container_ressource_modal'>
              <div className='display_none_desktop margin_auto_mobile' /* for responsive pas toucher */ >
                {/* Food Resource */}
                <div className='margin_desktop margin_desktop_perso'>
                  <img className="resource-icon-modal img_center" src={IconFood} alt="Icon food" />
                  <div className='resource-bar-container'>
                    <div
                      className='resource-bar'
                      style={{
                        width: `${colony ? (colony.food_storage / colony.max_food_storage) * 100 : 0}%`,
                        backgroundColor: 'blue'
                      }}
                    />
                  </div>
                  <p className="resource-value-modal">{colony ? colony.food_storage : null}/{colony ? colony.max_food_storage : null}</p>
                </div>
                {/* Water Resource */}
                <div className='margin_desktop'>
                  <img className="resource-icon-modal img_center" src={IconWater} alt="Icon water" />
                  <div className='resource-bar-container'>
                    <div
                      className='resource-bar'
                      style={{
                        width: `${colony ? (colony.water_storage / colony.max_water_storage) * 100 : 0}%`,
                        backgroundColor: 'blue'
                      }}
                    />
                  </div>
                  <p className='resource-value-modal'>{colony ? colony.water_storage : null}/{colony ? colony.max_water_storage : null}</p>
                </div>
              </div>
              <div className='display_none_desktop' /* for responsive pas toucher */ >
                {/* Wood Resource */}
                <div className='margin_desktop'>
                  <img className="resource-icon-modal img_center" src={IconWood} alt="Icon wood" />
                  <div className='resource-bar-container'>
                    <div
                      className='resource-bar'
                      style={{
                        width: `${colony ? (colony.wood_storage / colony.max_wood_storage) * 100 : 0}%`,
                        backgroundColor: 'blue'
                      }}
                    />
                  </div>
                  <p className="resource-value-modal">{colony ? colony.wood_storage : null}/{colony ? colony.max_wood_storage : null}</p>
                </div>
                {/* Honey Resource */}
                <div className='margin_desktop'>
                  <img className="resource-icon-modal img_center" src={IconHoney} alt="Icon honey" />
                  <div className='resource-bar-container mt5'>
                    <div
                      className='resource-bar'
                      style={{
                        width: `${colony ? (colony.honey_storage / colony.max_honey_storage) * 100 : 0}%`,
                        backgroundColor: 'blue'
                      }}
                    />
                  </div>
                  <p className="resource-value-modal">{colony ? colony.honey_storage : null}/{colony ? colony.max_honey_storage : null}</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>Loading colony information...</p>
        )}
      </Modal.Body>

    </Modal>
  );
};

export default ResourceModal;
