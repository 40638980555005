import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import antsMessage from '../../assets/angry_messagerie.png';
import antsBattle from '../../assets/angry-speakers.png';
import boump from '../../assets/sounds/Button_standart.mp3';
import { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';


const URL = process.env.REACT_APP_SERVER_URL;

const Messagerie = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const colony = useSelector((state) => state.auth.colony);
  
  const [animation, setAnimation] = useState(1);

  const clickSound = () => {
    let audio = document.getElementById('audio_btn');
    audio.volume = 0.1;
    audio.play();
  };

  const MarkAsReadedAttacks = async () => {
    const response = await axios.post(
      `${URL}/api/message_attack_read/${colony.id}`
    );
    return response.data;
  };

  const MarkAsReadedDeffences = async () => {
    const response = await axios.post(
      `${URL}/api/message_defence_read/${colony.id}`
    );
    return response.data;
  };

  const MarkAsReadedTDC = async () => {
    const response = await axios.post(
      `${URL}/api/message_tdc_read/${colony.id}`
    );
    return response.data;
  };

  const NewInsect = () => {
    setTimeout(() => {
      setAnimation(0);
    }, 1500);

    return (
      <div className=' animation-messagerie-fond'>
        <div className=' animation-messagerie-lazer'>
          <div className='animation-messagerie-lumiere'></div>
          <div className='animation-messagerie-lumiere mt20 '></div>
          <div className='animation-messagerie-lumiere mt32'></div>
          <img
            src={antsBattle}
            className='logo animation-messagerie-img '
            alt='Logo'
          />
        </div>
      </div>
    );
  };

  return (
    <div className='messagerie-title messagerie-miniNav'>
      <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>
      </audio>
      <img src={antsMessage} className='logo' alt='Logo' />
      <button
        className='button_1 fs20'
        onClick={ async(e) => {
          await MarkAsReadedTDC()
          navigate('/message_tdc');
          clickSound();
        }}
      >
        {t('message_tdc')}{' '}
      </button>
      <button
        className='button_1 fs20'
        onClick={ async(e) => {
          await MarkAsReadedAttacks()
          navigate('/message_attack');
          clickSound();
        }}
      >
        {t('message_attack')}{' '}
      </button>
      <button
        className='button_1 fs20'
        onClick={ async(e) => {
          await MarkAsReadedDeffences()
          navigate('/message_defence');
          clickSound();
        }}
      >
        {t('message_defence')}{' '}
      </button>
      {/* { animation && animation === 1 ?
         NewInsect()
        : null}          */}
    </div>
  );
};

export default Messagerie;
