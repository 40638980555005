import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import boump from '../../assets/sounds/Button_standart.mp3'

const URL = process.env.REACT_APP_SERVER_URL;

const ChatFooter = ({ socket, guildId }) => {
  const [message, setMessage] = useState('');

  const userId = useSelector((state) => state.auth.user?.id); 

  const handleTyping = () =>
    socket.emit('typing', `${localStorage.getItem('userName')} is typing`);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.trim() && userId && guildId !== 0) {
     
          socket.emit('message', {
          message: message,
          user_id : userId,
          guild_id : guildId,
          id: `${socket.id}${Math.random()}`,
          socketID: socket.id,
        });     

      const handler = async()=> {
        await CreateGuildMessage(guildId, userId, message)
        setMessage('');
      }
      handler()
      clickSound()
    }
  };

  const CreateGuildMessage = async(guild_id, userId, message) => {
    try {
      let response = await axios.post(`${URL}/api/messages_guild/create`, {
        guild_id : guild_id,
        user_id : userId,
        message : message
      });
      return response.data

    } catch (error) {
      console.log("create message err : " , error)
    }
  }

  const clickSound = () => {
    let audio = document.getElementById('audio_btn')
    audio.volume = 0.1
    audio.play()
  }
  
  return (
    <div className="chat__footer">
        <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>     
      </audio> 
      <form className="form" >
        <div>
        <input
          type="text"
          placeholder="Write message"
          className="message"
          value={message}
          onChange={(e) => {
            if((e.target.value).split('').length <= 250){
              setMessage(e.target.value)
            }else{
              return
            }
          }}
          onKeyDown={handleTyping}
        />
        <p>{message.split('').length}/250 </p>
        </div>
          <button onClick={ handleSendMessage} className="sendBtn">SEND</button>
      </form>
    </div>
  );
};

export default ChatFooter;