import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchColony,
  updateSurface,
  updateWorkersCount,
} from '../features/authSlice';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import AcuerirTerrain from './AcquerirTerrain';
import VolumBar from '../components/assets/VolumBar';
import apple from '../assets/IconFood.png';
import branche from '../assets/IconWood.png';
import { useTranslation } from 'react-i18next';
import workerFood from '../assets/ant_avec_pomme.png';
import workerWood from '../assets/ant_avec_bois.png';
import workerImg from '../assets/unit_0.png';
import ResourceInfo from '../components/InformationModal.js/RessourceInfo';
import { IoEarth } from 'react-icons/io5';
import BlackAntsAnimation from '../components/assets/AnimationModal/blackAntsAnimation';
import boump from '../assets/sounds/Button_standart.mp3';

const URL = process.env.REACT_APP_SERVER_URL;

export const Ressources = ({ socket }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const accessToken = useSelector((state) => state.auth.accessToken);
  const colony = useSelector((state) => state.auth.colony);

  const [antsForFood, setAntsForFood] = useState(null);
  const [antsForWood, setAntsForWood] = useState(null);
  const [workersCount, setWorkersCount] = useState(0);
  const [repartitionSucces, setRepartitionSucces] = useState('');
  const [showResourceInfo, setShowResourceInfo] = useState(false);
  const [animation, setAnimation] = useState(0);

  const handleResourceInfoClose = () => {
    setShowResourceInfo(false);
    clickSound();
  };
  const handleResourceInfoShow = () => {
    setShowResourceInfo(true);
    clickSound();
  };

  const formatTime = () => {
    const now = new Date();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    let targetMinute;
    if (minutes < 20) {
      targetMinute = 20;
    } else if (minutes < 40) {
      targetMinute = 40;
    } else {
      targetMinute = 60;
    }

    let milliseconds =
      (targetMinute - minutes - 1) * 60000 + (60 - seconds) * 1000;

    const totalSeconds = Math.floor(milliseconds / 1000);
    const remainingMinutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;
    return `${remainingMinutes}m ${remainingSeconds}s`;
  };

  useEffect(() => {
    const handleFetchColony = (data) => {
      if (colony && data && data.id === colony.id) {
        dispatch(fetchColony(navigate));
        console.log('fetch colonyModel');
      }
    };
    const handleFetchWorkerss = async (data) => {
      if (colony && data && data.id === colony.id) {
        await getWorkersCount();
        console.log('fetch armyUnitModel');
      }
    };
    socket.on('colonyModel', handleFetchColony);
    socket.on('armyUnitModel', handleFetchWorkerss);

    return () => {
      socket.off('colonyModel', handleFetchColony);
      socket.off('armyUnitModel', handleFetchWorkerss);
    };
  }, [socket]);

  useEffect(() => {
    const interval = setInterval(async () => {
      setRepartitionSucces('');
    }, 3000);

    if (!colony) {
      dispatch(fetchColony(navigate));
    }

    if (workersCount === 0 && colony) {
      const handleWorkers = async () => {
        await getWorkersCount();
      };
      handleWorkers();
    }

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (
      location.state &&
      location.state.partOfFood &&
      location.state.partOfWood
    ) {
      if (workersCount && colony.terrain_chasse < workersCount) {
        if (location.state.partOfFood < 0) {
          setAntsForFood(0);
          setAntsForWood(colony.terrain_chasse);
        } else if (location.state.partOfFood > 1) {
          setAntsForFood(colony.terrain_chasse);
          setAntsForWood(0);
        } else {
          setAntsForFood(
            Math.round(location.state.partOfFood * colony.terrain_chasse)
          );
          setAntsForWood(
            Math.round(location.state.partOfWood * colony.terrain_chasse)
          );
        }
      } else {
        if (location.state.partOfFood < 0) {
          setAntsForFood(0);
          setAntsForWood(workersCount);
        } else if (location.state.partOfFood > 1) {
          setAntsForFood(workersCount);
          setAntsForWood(0);
        } else {
          if (workersCount && workersCount === 0) {
            setAntsForFood(colony.antsForFood);
            setAntsForWood(colony.antsForWood);
          } else {
            setAntsForFood(
              Math.round(location.state.partOfFood * workersCount)
            );
            setAntsForWood(
              Math.round(location.state.partOfWood * workersCount)
            );
          }
        }
      }
    }
  }, [location.state]);

  const fetchWorkers = async (colony_id) => {
    try {
      const response = await axios.get(`${URL}/api/units/workers/${colony_id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };

  const clickSound = () => {
    let audio = document.getElementById('audio_btn');
    audio.volume = 0.1;
    audio.play();
  };

  const handleUpdateWorkers = async () => {
    clickSound();
    if (accessToken && colony) {
      let count = await fetchWorkers(colony.id); //////erreur au bout de 5 clicks / 20seg !!!!!!!!!!!!!!!!!!!!
      if (count) {
        let maxWorkers = count;
        let surface = colony.terrain_chasse;
        if (
          maxWorkers >=
            Number(colony ? colony.antsForFood : 0) +
              Number(colony ? colony.antsForWood : 0) &&
          surface >=
            Number(colony ? colony.antsForFood : 0) +
              Number(colony ? colony.antsForWood : 0)
        ) {
          dispatch(
            updateWorkersCount({ antsForFood, antsForWood, maxWorkers })
          );
          setRepartitionSucces('Ouvrières répartient avec succès');
        } else {
          dispatch(
            updateWorkersCount({ antsForFood, antsForWood, maxWorkers })
          );
          setRepartitionSucces(
            'Ouvrières répartit avec succès mais votre terrain de chasse est trop petit certaines ouvrières sont inéficaces. '
          );
        }
        if (antsForWood < antsForFood) {
          setAnimation(1);
          setTimeout(() => {
            setAnimation(0);
          }, 2000);
        } else {
          setAnimation(2);
          setTimeout(() => {
            setAnimation(0);
          }, 2000);
        }
      }
    }
  };

  const getWorkersCount = async () => {
    if (colony) {
      const count = await fetchWorkers(colony.id);
      setWorkersCount(count);
      return count;
    }
  };

  return (
    <div className='ressource-page'>
      <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>
      </audio>
      <div className='ressource'>
        <div className='ressource-handler'>
          <ResourceInfo
            show={showResourceInfo}
            handleClose={handleResourceInfoClose}
          />
          <div className='ressource-info'>
            <div className='component_territory'>
              {' '}
              <div>
                <p>
                  <IoEarth className=' icon_ressource_modal icon_color_earth' />{' '}
                </p>
                <p>
                  {colony ? colony.terrain_chasse : null}
                  {''}
                </p>
              </div>
              <div>
                <p>
                  <img src={workerImg} className='logo' alt='Logo' />
                </p>
                <p>{workersCount} </p>
              </div>
            </div>
            <div className='earn-ressource-info'>
              <p className='mt5 '>
                {formatTime()} {' : '}{' '}
              </p>
              <p className='ml10'>
                + {antsForFood ? antsForFood : colony ? colony.antsForFood : 0}
                <img
                  src={apple}
                  className='mr15 icon_ressource_modal '
                  alt='Logo'
                />{' '}
                + {antsForWood ? antsForWood : colony ? colony.antsForWood : 0}{' '}
                <img
                  src={branche}
                  className='mr10 icon_ressource_modal '
                  alt='Logo'
                />
                <button
                  onClick={handleResourceInfoShow}
                  className='button_info '
                >
                  i
                </button>
              </p>
            </div>
          </div>
          <div className='ressource-list-input'>
            <div className=' component_ressource'>
              <p className='fs45 more-ressources '>
                <img src={workerFood} className='logo' alt='Logo' />
              </p>
              <div className='form-group-ressources'>
                <input
                  type='number'
                  value={
                    antsForFood && antsForFood === 0
                      ? 0
                      : antsForFood && antsForFood >= 0
                        ? antsForFood
                        : colony
                          ? colony.antsForFood
                          : 0
                  }
                  onChange={(e) => {
                    if (e.target.value <= 0) {
                      setAntsForFood(0);
                    } else if (
                      e.target.value > workersCount &&
                      workersCount <= colony.terrain_chasse
                    ) {
                      setAntsForFood(workersCount);
                      setAntsForWood(0);
                    } else if (e.target.value > workersCount) {
                      setAntsForFood(colony.terrain_chasse);
                      setAntsForWood(0);
                    } else {
                      setAntsForFood(e.target.value);
                    }
                  }}
                />
              </div>
              <div>
                <button
                  onClick={(e) => {
                    if (workersCount >= colony.terrain_chasse) {
                      setAntsForFood(colony.terrain_chasse);
                      setAntsForWood(0);
                      clickSound();
                    } else {
                      setAntsForFood(workersCount);
                      setAntsForWood(0);
                      clickSound();
                    }
                  }}
                  className='button_1 ressource-button-max'
                >
                  {t('resource_max_food')}
                </button>
              </div>
            </div>
            <div className='component_ressource'>
              <p className='fs45 more-ressources '>
                <img src={workerWood} className='logo' alt='Logo' />
              </p>
              <div className='form-group-ressources'>
                <input
                  type='number'
                  value={
                    antsForWood && antsForWood === 0
                      ? 0
                      : antsForWood && antsForWood >= 0
                        ? antsForWood
                        : colony
                          ? colony.antsForWood
                          : 0
                  }
                  onChange={(e) => {
                    if (e.target.value <= 0) {
                      setAntsForWood(0);
                    } else if (
                      e.target.value > workersCount &&
                      workersCount <= colony.terrain_chasse
                    ) {
                      setAntsForWood(workersCount);
                      setAntsForFood(0);
                    } else if (
                      e.target.value > workersCount &&
                      workersCount >= colony.terrain_chasse
                    ) {
                      setAntsForWood(colony.terrain_chasse);
                      setAntsForFood(0);
                    } else {
                      setAntsForWood(e.target.value);
                    }
                  }}
                />
              </div>
              <div>
                <button
                  onClick={(e) => {
                    if (workersCount >= colony.terrain_chasse) {
                      setAntsForWood(colony.terrain_chasse);
                      setAntsForFood(0);
                      clickSound();
                    } else {
                      setAntsForWood(workersCount);
                      setAntsForFood(0);
                      clickSound();
                    }
                  }}
                  className='button_1 ressource-button'
                >
                  {t('resource_max_wood')}
                </button>
              </div>
            </div>
          </div>

          {colony ? <VolumBar /> : null}
          <p>
            {Math.round(
              (Number(
                antsForFood ? antsForFood : colony ? colony.antsForFood : 0
              ) /
                (Number(
                  antsForFood ? antsForFood : colony ? colony.antsForFood : 0
                ) +
                  Number(
                    antsForWood ? antsForWood : colony ? colony.antsForWood : 0
                  ))) *
                100
            )}
            % /{' '}
            {Math.round(
              (Number(
                antsForWood ? antsForWood : colony ? colony.antsForWood : 0
              ) /
                (Number(
                  antsForFood ? antsForFood : colony ? colony.antsForFood : 0
                ) +
                  Number(
                    antsForWood ? antsForWood : colony ? colony.antsForWood : 0
                  ))) *
                100
            )}
            %
          </p>
          <p>
            {t('resource_territory_occupation')}{' '}
            {Math.round(
              ((Number(
                antsForFood ? antsForFood : colony ? colony.antsForFood : 0
              ) +
                Number(
                  antsForWood ? antsForWood : colony ? colony.antsForWood : 0
                )) *
                100) /
                Number(colony ? colony.terrain_chasse : 0)
            )}{' '}
            %
          </p>
          <p className='ressource-info-repartition'>{repartitionSucces}</p>
          <button
            onClick={(e) => {
              handleUpdateWorkers();
            }}
            className='button_1 ressource-button'
          >
            {t('repartir')}
          </button>
          <div className='animation-ressource1'>
            {BlackAntsAnimation(animation || 0)}
          </div>
          <div className='animation-ressource2'>
            {BlackAntsAnimation(animation || 0)}
          </div>
          <div className='animation-ressource3'>
            {BlackAntsAnimation(animation || 0)}
          </div>
          <p>
            {t('recolteQuoti')}{' '}
            {Number(
              antsForFood ? antsForFood : colony ? colony.antsForFood : 0
            ) +
              Number(
                antsForWood ? antsForWood : colony ? colony.antsForWood : 0
              ) <=
              workersCount &&
            Number(
              antsForFood ? antsForFood : colony ? colony.antsForFood : 0
            ) +
              Number(
                antsForWood ? antsForWood : colony ? colony.antsForWood : 0
              ) <=
              colony
              ? colony.terrain_chasse
              : 0
                ? Number(
                    antsForFood ? antsForFood : colony ? colony.antsForFood : 0
                  ) * 24
                : null}{' '}
            <img
              src={apple}
              className='mr15 icon_ressource_modal '
              alt='Logo'
            />
            et{' '}
            {Number(
              antsForFood ? antsForFood : colony ? colony.antsForFood : 0
            ) +
              Number(
                antsForWood ? antsForWood : colony ? colony.antsForWood : 0
              ) <=
              workersCount &&
            Number(
              antsForFood ? antsForFood : colony ? colony.antsForFood : 0
            ) +
              Number(
                antsForWood ? antsForWood : colony ? colony.antsForWood : 0
              ) <=
              colony
              ? colony.terrain_chasse
              : 0
                ? Number(
                    antsForWood ? antsForWood : colony ? colony.antsForWood : 0
                  ) * 24
                : null}{' '}
            <img
              src={branche}
              className='mr10 icon_ressource_modal'
              alt='Logo'
            />
            .
          </p>
        </div>
      </div>
      <div className='ressource'>{AcuerirTerrain({ socket })}</div>
    </div>
  );
};
