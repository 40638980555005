import React, { useEffect } from 'react';
import './App.css';
import './App_global.css';
import './assets_styles.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from 'react-router-dom';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Home from './pages/Home';
import Construction from './pages/Construction';
import NavBar from './components/NavBar';
import { login, logout } from './features/authSlice';
import Laboratoire from './pages/Laboratoire';
import { Ressources } from './pages/Ressources';
import Army from './pages/Army';
import Map from './pages/Map';
import Enemy from './pages/Enemy';
import AcquerirTerrain from './pages/AcquerirTerrain';
import ColonyAndArmies from './pages/ColonyAndArmies';
import Tuto from './pages/Tuto';
import Guilds from './pages/Guild';
import Acceuil from './pages/Acceuil';
import Convois from './pages/Convois';
import ChallengeTuto from './components/ChallengeTuto';
import CommingSoon from './pages/CommingSoon';
import MessagerieTDC from './pages/messagerie/MessagerieTDC';
import MessagerieAttack from './pages/messagerie/MessagerieAttack';
import MessagerieDefence from './pages/messagerie/MessagerieDefence';
import Forest_Tropical from './Forest_Tropical.mp3'
import socketIO from 'socket.io-client';
import FeedBack from './components/FeedBack';

const URL = process.env.REACT_APP_SERVER_URL;

const App = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const socket = socketIO.connect(`${URL}`);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isAuthenticated') === 'true';
    if (isLoggedIn) {
      dispatch(login());
    } else {
      dispatch(logout());
    }
  }, [dispatch]);

  return (
    <Router>
      <AppContent isAuthenticated={isAuthenticated} socket={socket} />
    </Router>
  );
};

const AppContent = ({ isAuthenticated, socket }) => {
  const location = useLocation(); // Utilisation de useLocation dans le bon contexte

  return (
    <>
      {isAuthenticated && location.pathname !== '/acceuil' && (
        <div className='container_nav'>
          <NavBar socket={socket} />
          <div className='beta_div'>
            Alpha 0.1.0.0
          </div>
        </div>
      )}
      <Routes className='mr20'>
        <Route path='/acceuil' element={<Acceuil />} />
        {/* <Route path='/login' element={<Login />} /> */}
        {/* <Route path='/register' element={<Register />} /> */}
        {isAuthenticated ? (
          <>
            <Route path='/' element={<Home socket={socket} />} />
            <Route path='/construction' element={<Construction socket={socket} />} />
            <Route path='/laboratoire' element={<Laboratoire socket={socket} />} />
            <Route path='/colonies' element={<ColonyAndArmies />} />
            <Route path='/army' element={<Army socket={socket}/>} />
            <Route path='/map' element={<Map />} />
            <Route path='/ennemies' element={<Enemy socket={socket}/>} />
            <Route path='/ressources' element={<Ressources socket={socket} />} />
            <Route path='/acquerirTerrain' element={<AcquerirTerrain />} />
            <Route path='/convois' element={<Convois />} />
            <Route path='/message_tdc' element={<MessagerieTDC />} />
            <Route path='/message_attack' element={<MessagerieAttack />} />
            <Route path='/message_defence' element={<MessagerieDefence />} />
            <Route path='/tuto' element={<Tuto />} />
            <Route path='/guilde' element={<Guilds socket={socket} />} />
            <Route path='/challengeTuto' element={<ChallengeTuto />} />
            <Route path='/commingSoon' element={<CommingSoon />} />
            <Route path='/feedBack' element={<FeedBack />} />

          </>
        ) : (
          <Route path='*' element={<Navigate to='/acceuil' />} />
        )}
      </Routes>
    </>
  );
};

export default App;
