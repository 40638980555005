import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import logo from '../assets/provisoire_image.jpg';
import { FaHourglassHalf } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import ErrorModal from '../components/assets/ErrorModal';
import IconFood from '../assets/IconFood.png';
import IconWater from '../assets/IconWater.png';
import IconHoney from '../assets/IconHoney.png';
import IconWood from '../assets/IconWood.png';
import boump from '../assets/sounds/Button_standart.mp3';
import { LuChevronsUp } from 'react-icons/lu';

const URL = process.env.REACT_APP_SERVER_URL;

const Construction = ({ socket }) => {
  const { t } = useTranslation();

  const [buildings, setBuildings] = useState([]);
  const colonyId = useSelector((state) => state.auth.colony.id);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);

  const handleErrorClose = () => setShowError(false);
  const handleErrorShow = (message) => {
    setErrorMessage(message);
    setShowError(true);
  };

  const fetchColonyBuildings = async () => {
    try {
      const response = await axios.get(
        `${URL}/api/colonies/${colonyId}/buildings`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setBuildings(response.data);
    } catch (error) {
      console.error('Error fetching colony buildings:', error);
    }
  };

  useEffect(() => {
    const handleFetchBuildings = async (data) => {
      if (colonyId && data && data.id === colonyId) {
        await fetchColonyBuildings();
        console.log('fetch colonyBuildingModel');
      }
    };
    socket.on('colonyBuildingModel', handleFetchBuildings);

    return () => {
      socket.off('colonyBuildingModel', handleFetchBuildings);
    };
  }, [socket]);

  useEffect(() => {
    if (buildings.length === 0 && colonyId) {
      const handleBuildingLevels = async () => {
        await fetchColonyBuildings();
      };
      handleBuildingLevels();
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setBuildings((buildings) =>
        buildings.map((building) => {
          if (building.buildEndTime) {
            const now = moment();
            const end = moment(building.buildEndTime);
            const duration = moment.duration(end.diff(now));
            if (duration.asSeconds() <= 0) {
              fetchColonyBuildings();
              return { ...building, buildEndTime: null };
            }
          }
          return building;
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleUpgrade = async (buildingId) => {
    if (!buildingId) {
      console.error('Building ID is undefined');
      return;
    }
    console.log('Trying to upgrade building with ID:', buildingId);
    try {
      const response = await axios.post(
        `${URL}/api/buildings/upgrade`,
        {
          colony_id: colonyId,
          building_id: buildingId,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log('Upgrade successful:', response.data);
      fetchColonyBuildings();
      clickSound();
    } catch (error) {
      handleErrorShow(
        'Veuillez  consulter vos ressources ou veuillez vous déconnecter/reconnecter.'
      );
    }
  };

  const handleCancel = async (buildingId) => {
    try {
      const response = await axios.post(
        `${URL}/api/buildings/cancel`,
        {
          colony_id: colonyId,
          building_id: buildingId,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log('Cancel successful:', response.data);
      fetchColonyBuildings();
      clickSound();
    } catch (error) {
      console.error('Error cancelling building:', error);
    }
  };

  const formatDuration = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');
    return `${duration.days()}J ${duration.hours()}H ${duration.minutes()}m ${duration.seconds()}s`;
  };

  const getRemainingTime = (endTime) => {
    const now = moment();
    const end = moment(endTime).add(1, 'minute');
    const duration = moment.duration(end.diff(now));
    return `${duration.days()}J ${duration.hours()}H ${duration.minutes()}m ${duration.seconds()}`;
  };

  const hasOngoingConstruction = buildings.some(
    (building) =>
      building.buildEndTime && moment(building.buildEndTime).isAfter(moment())
  );

  const ongoingConstruction = buildings.find(
    (building) =>
      building.buildEndTime && moment(building.buildEndTime).isAfter(moment())
  );

  const clickSound = () => {
    let audio = document.getElementById('audio_btn');
    audio.volume = 0.1;
    audio.play();
  };

  return (
    <div>
      <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>
      </audio>
      {ongoingConstruction && (
        <p>
          {t('remaining_time')}:{' '}
          {getRemainingTime(ongoingConstruction.buildEndTime)}
        </p>
      )}
      <ul className='ul_construction police_Electrolize'>
        {buildings.map((building) => (
          <li className='list_construction mb20' key={building.id}>
            <div>
              <div className='flex background_info_build_resh'>
                <div className='info_construction flex'>
                  <div className='box_niv_name'>
                    <h3>{building.name}</h3>
                    <p className='h3_big_width fs20'>
                      {t('level')} {building.currentLevel}
                    </p>
                  </div>
                  <div>
                    {building.nextLevelCosts ? (
                      <div>
                        <ul
                          className='flex ul_construction_ressources'
                          style={{ listStyle: 'none' }}
                        >
                          <li className='mr20 fs22 flex'>
                            <img className='width50' src={IconFood} alt='' />
                            <p className='fs22 ml8 lg_fs20'>
                              {building.nextLevelCosts.food}
                            </p>
                          </li>
                          <li className='mr20 fs22 flex'>
                            <img className='width50' src={IconWater} alt='' />
                            <p className='fs22 ml8 lg_fs20'>
                              {building.nextLevelCosts.water}
                            </p>
                          </li>
                          <li className='mr20 fs22 flex'>
                            <img className='width50' src={IconWood} alt='' />
                            <p className='fs22 ml8 lg_fs20'>
                              {building.nextLevelCosts.wood}
                            </p>
                          </li>
                          <li className='mr20 fs22 flex'>
                            <img className='width50' src={IconHoney} alt='' />
                            <p className='fs22 ml8 lg_fs20'>
                              {building.nextLevelCosts.honey}
                            </p>
                          </li>
                        </ul>
                        <li className='mr40 fs22 flex li_time'>
                          <p>{formatDuration(building.nextLevelCosts.time)}</p>
                          <FaHourglassHalf className='mr15 fs22' />
                        </li>
                      </div>
                    ) : (
                      <p>{t('no_next_level_info')}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className='flex'>
                <div>
                  <img
                    className='image_around_construction'
                    src={
                      building.imageUrl ? `${URL}${building.imageUrl}` : logo
                    }
                    alt='building'
                  />
                </div>
                <div className=''>
                  <p>
                    {t('description')}: {building.description}
                  </p>
                  <button
                    onClick={() => handleUpgrade(building.buildingId)}
                    disabled={hasOngoingConstruction}
                    className={`button_upgrade ${
                      hasOngoingConstruction ? 'button-disabled' : ''
                    }`}
                  >
                    <LuChevronsUp />
                  </button>
                </div>
              </div>
            </div>
            {hasOngoingConstruction && building.buildEndTime && (
              <div
                style={{
                  padding: '10px',
                  backgroundColor: '#f8f9fa',
                  textAlign: 'center',
                }}
              >
                <p style={{ color: 'red' }}>{t('under_construction')}</p>
                <p>
                  {t('remaining_time')}:{' '}
                  {getRemainingTime(building.buildEndTime)}
                </p>
                {building.buildEndTime && (
                  <button
                    onClick={() => handleCancel(building.buildingId)}
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                      padding: '10px',
                      border: 'none',
                      borderRadius: '5px',
                      marginLeft: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    {t('cancel')}
                  </button>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>

      {hasOngoingConstruction && <p>{t('construction_ongoing_message')}</p>}
      <ErrorModal
        show={showError}
        handleClose={handleErrorClose}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default Construction;
